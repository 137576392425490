import { createStore } from 'vuex'

export default createStore({
  state: {
    isActiveSidebar: false
  },
  getters: {
    getSidebarStatus: state => state.isActiveSidebar
  },
  mutations: {
    switchSidebarStatus (state, payload = !state.isActiveSidebar) {
      state.isActiveSidebar = payload
    }
  },
  actions: {},
  modules: {}
})
