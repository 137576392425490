import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home/index.vue')
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('../views/PrivacyPolicy/index.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: (to, from, scrollPosition) => {
    store.commit('switchSidebarStatus', false)
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth', top: 64 } // Header offset ($unit * 4)
    }
    return scrollPosition || { left: 0, top: 0 }
  }
})

export default router
