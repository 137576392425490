import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Header',
  computed: {
    ...mapGetters(['getSidebarStatus'])
  },
  methods: {
    ...mapMutations(['switchSidebarStatus'])
  }
}
