import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import mixins from './mixins'
import './registerServiceWorker'

import Button from './components/Button/index.vue'
import Modal from './components/Modal/index.vue'
import Container from './components/Container/index.vue'
import Icon from './components/Icon/index.vue'
import Animation from './components/Animation/index.vue'

const mountApp = () =>
  createApp(App)
    .use(store)
    .use(router)
    .mixin(mixins)
    .component('Button', Button)
    .component('Modal', Modal)
    .component('Container', Container)
    .component('Icon', Icon)
    .component('Animation', Animation)
    .mount('#app')

mountApp()
