export default {
  name: 'Button',
  props: {
    variant: {
      type: String,
      default: 'primary',
      validator (val) {
        return [
          'primary',
          'secondary',
          'light',
          'dark',
          'success',
          'warning',
          'danger'
        ].includes(val)
      }
    },
    type: {
      type: String,
      validator: val => ['outline', 'text', 'empty'].includes(val)
    },
    icon: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    small: {
      type: Boolean
    },
    to: {
      type: [Object, String]
    },
    target: {
      type: String
    },
    submit: {
      type: Boolean
    },
    nospacing: {
      type: Boolean
    }
  }
}
