export default {
  name: 'Animation',
  props: {
    direction: {
      type: String,
      validator (val) {
        return ['top', 'left', 'bottom', 'right'].includes(val)
      }
    },
    mode: {
      type: String,
      default: 'fade',
      validator (val) {
        return ['fade'].includes(val)
      }
    },
    event: {
      type: String,
      default: 'in',
      validator (val) {
        return ['in'].includes(val)
      }
    }
  },
  data () {
    return {
      observer: null
    }
  },
  mounted () {
    this.observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (
            entry.isIntersecting &&
            !entry.target.classList.contains('show')
          ) {
            entry.target.classList.add('show')
          }
        })
      },
      {
        threshold: 0.25
      }
    )

    this.observer.observe(this.$el)
  },
  beforeUnmount () {
    this.observer.disconnect()
  }
}
