export default {
  name: 'Icon',
  props: {
    variant: {
      type: String,
      default: 'primary',
      validator (val) {
        return [
          'primary',
          'secondary',
          'light',
          'dark',
          'success',
          'warning',
          'danger'
        ].includes(val)
      }
    },
    name: {
      type: String,
      required: true
    },
    style: {
      type: String,
      default: 'fad',
      validator (val) {
        return ['fas', 'fal', 'fad', 'fab'].includes(val)
      }
    },
    spacing: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    icon () {
      return `fa-${this.name}`
    }
  }
}
