export default {
  name: 'Modal',
  props: {
    show: {
      type: Boolean,
      required: true
    },
    size: {
      type: String,
      default: 'md',
      validator (val) {
        return ['sm', 'md', 'lg', 'xl', 'fluid'].includes(val)
      }
    },
    staticHeight: {
      type: Boolean
    }
  }
}
