function getTitle (vm) {
  const { title } = vm.$options
  if (title) {
    return typeof title === 'function' ? title.call(vm) : title
  }
}
export default {
  methods: {
    $useImage (name) {
      return require(`../assets/img/${name}`)
    },
    $useVideo (name) {
      return require(`../assets/video/${name}`)
    },
    $useClipboard (text) {
      navigator.clipboard.writeText(text)
    },
    $useNavigation (url) {
      window.open(url)
    },
    $test (event) {
      console.log(event)
    }
  },
  created () {
    const title = getTitle(this)
    if (title) {
      document.title = title
    }
  }
}
